<template>
    <div class="contact">
        <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="listFinishedText"
            @load="onLoad"
            >
            <van-cell-group :border="false">
                <van-cell v-for="item in list" :key="item.id" title="单元格">
                    <template #title>
                        <div>
                            <div>
                                {{item.contact_name}} ({{item.contact_type}}) 
                            </div>
                            <div>
                                {{item.contact_tellphone}}
                            </div>
                        </div>
                    </template>
                    <template #right-icon>

                        <div @click="onEdit(item.id)" class="contact-right-icon">
                            <van-icon size="18" name="edit" />
                        </div>

                        <div style="margin-left:15px;" @click="onDel(item.id)" class="contact-right-icon">
                            <van-icon size="18" name="close" />
                        </div>

                    </template>
                </van-cell>
            </van-cell-group>
        </van-list>
        <van-empty v-if="list.length == 0" description="暂无相关数据~" />
        <div class="contact-btn">
            <van-button @click="onAdd" round type="primary" block>关联家属</van-button>
        </div>
        <loadings :loading='searchLoading' />
    </div>
</template>

<script>
    import { Cell, CellGroup, Empty } from 'vant'
    import Loadings from '@/components/loading'
    export default {
        name:"contact",
        data() {
            return {
                list: [],
                loading:false,
                finished: false,
                searchLoading: false,
                listFinishedText: '没有更多了',
                pageSize: 10,
                pageCurrent: 1,
            }
        },
        components: {
            [Cell.name]:Cell,
            [Empty.name]:Empty,
            [CellGroup.name]:CellGroup,
            loadings:Loadings,
        },
        created () {
            this.searchLoading = true
        },
        methods: {
            getList(){
                this.$api.profile_contact()
                .then(res=>{
                    this.loading = false
                    this.searchLoading = false
                    this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
                    this.finished = this.list.length >= res._meta.totalCount
                    this.listFinishedText = this.list.length ? '没有更多了~' : ''
                    this.pageCurrent += 1
                })
            },
            onLoad() {
                this.getList()
            },
            onAdd(){
                this.$router.push({path:'/contact/edit'})
            },
            onDel(id){
                this.searchLoading = true;
                this.$api.profile_del_contact({id:id})
                .then(res=>{
                    this.$toast.success('操作成功~')
                    this.getList()
                    this.searchLoading = false;
                })
                .catch(err=>{
                    this.$toast.fail('操作失败~')
                })
            },
            onEdit(id){
                this.$router.push({path:'/contact/edit',query:{id:id}})
            },
        },
    }
</script>

<style lang="less">
    .contact{
        .van-cell-group{
            width: 95%;
            margin: 0 auto;
            background: none;
        }
        .van-cell{
            margin-top: 10px;
            border-radius: 6px;
        }
        .van-cell__title{
            flex: 4;
        }
        .contact-right-icon{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .contact-btn{
            width: 100%;
            position: fixed;
            bottom: 0;
            background: #fff;
            padding: 5px 0;
            .van-button--block{
                width: 90%;
                margin: 0 auto;
            }
        }
    }
</style>